<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\list.vue
  FILE_NAME : list
  AUTHOR : bykim
  DATE : 2023-12-19
  DESCRIPTION : 근무정보설정 탭 메인 화면
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="추가수당설정" v-if="tabIndex === 0">
        <ExtraAllowanceList ref="eaListRef"/>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
import ExtraAllowanceList from "./extra-allowance-list.vue";

export default {
  components: {
    ExtraAllowanceList,
    Tabs,
    Tab,
  },
  props: {},
  data() {
    return {
      tabIndex: 0,
      tabs: {
        selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
      },
    }
  },
  computed: {},
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>

</style>